import { Conversation, ConversationAndLastMessage, ConversationWithLastMessage } from '../../types/src/conversation';
import TicketStatus from '../../types/src/ticketStatus';
import { UnreadMessagesUtil } from './unread-messages.util';

export class ConversationUtil {
  public static isConversationOpen = (conversation: Conversation): boolean =>
    conversation.ticketHistory?.at(-1)?.updatedStatus !== TicketStatus.CLOSED;
  public static hasLastMessage = (
    conversationAndLastMessage: ConversationAndLastMessage
  ): conversationAndLastMessage is ConversationWithLastMessage => {
    const { conversation, lastMessage } = conversationAndLastMessage;
    return !!lastMessage && !UnreadMessagesUtil.isIgnoredInitialMessage({ ...lastMessage, conversation });
  };
}
