import { ShoppingCartProduct } from './shoppingCart';

export interface ShopCoopSecrets {
  apiKey: string;
}

export interface ShopSale {
  customerNumber: number;
  conversationID?: string;
  articles?: ShopArticle[];
  receipts?: (ShopEPrescription | ShopPrescriptionImage)[];
  price?: {
    totalBrutto: string;
    discount: string;
    grandTotal: string;
    shippingCost: string;
    tax: string;
    couponCode?: string;
  };
  pharmacyComment?: string;
  billingAddress: ShopBillingAddress;
}

export interface ShopArticle {
  title: string;
  sku: string;
  priceBrutto: string;
  originalPriceBrutto: string;
  discount?: string;
  tax: string;
  taxRate: string;
  quantity: number;
}

export interface ShopEPrescription {
  eprescriptionCode: { tokens: string[] };
  originalPrescriptionInPharmacy: boolean;
  title?: string;
}

export interface ShopPrescriptionImage {
  prescriptionImageBase64: string;
  prescriptionFileName: string;
  originalPrescriptionInPharmacy: boolean;
  title?: string;
}

export interface ValidShopProduct extends ShoppingCartProduct {
  name: string;
  price: number;
  retailPrice: number;
  tax: number;
  taxRate: number;
}

export function isValidShopProduct(product: ShoppingCartProduct): product is ValidShopProduct {
  return !!product.name && !!product.retailPrice && !!product.price && !!product.tax && !!product.taxRate;
}

// From Swagger, #/components/schemas/BillingAddressRequest
export interface ShopBillingAddress {
  firstName: string;
  lastName: string;
  email: string;
  company?: string;
  street?: string;
  zip?: string;
  city?: string;
  phone?: string;
  salutation?: string;
  dateOfBirth?: string;
}
