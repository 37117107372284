import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../../essentials/types/src/actionType';
import { DeletionEntry, TicketEvent } from '../../../../../essentials/types/src/backendConversation';
import { ChatUser } from '../../../../../essentials/types/src/chatUser';

export const openDeleteChatModal = createAction(
  `${ActionType.Chat}: Open delete chat modal`,
  props<{ conversationId: string }>()
);

export const updateTicketHistory = createAction(
  `${ActionType.Chat}: Update ticket history`,
  props<{ conversationId: string; ticketEvent: TicketEvent }>()
);

export const updateOpenTicketReminderNotification = createAction(
  `${ActionType.Chat}: Update open ticket reminder notification`,
  props<{
    conversationId: string;
    backendUserConversationId: string;
    reminderNotification: boolean;
    showReminder?: boolean;
  }>()
);

export const updateDeletionRecordForConversation = createAction(
  `${ActionType.Chat}: Update deletion record for conversation`,
  props<{ conversationId: string; deletionRecord: DeletionEntry[] }>()
);

export const updateTicketHistoryForConversation = createAction(
  `${ActionType.Chat}: Update ticket history for conversation`,
  props<{ conversationId: string; ticketHistory: TicketEvent[] }>()
);

export const updateOpenTicketReminderNotificationForConversation = createAction(
  `${ActionType.Chat}:  Update open ticket reminder notification for conversation`,
  props<{ conversationId: string; reminderNotification: boolean; showReminder?: boolean }>()
);

export const updateConversationLinkForConversation = createAction(
  `${ActionType.Chat}: Update user notification for conversation`,
  props<{ conversationId: string; conversationLink: string }>()
);

export const updateConversationChatPartner = createAction(
  `${ActionType.Chat}: Update chat partner in conversation`,
  props<{ conversationId: string; chatPartner: ChatUser }>()
);

export const updateArchivedConversation = createAction(
  `${ActionType.Chat}: Update archived by enduser for conversation`,
  props<{ conversationId: string; archivedByEnduser: boolean }>()
);

export const updateAppointmentId = createAction(
  `${ActionType.Chat}: Update appointment id for conversation`,
  props<{ conversationId: string; appointmentId: string }>()
);

export const updateHasPrescriptions = createAction(
  `${ActionType.Chat}: Update hasPrescriptions for conversation`,
  props<{ conversationId: string; hasPrescriptions: boolean }>()
);
