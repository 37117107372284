import { UserAgent } from '@adeprez/capacitor-user-agent';
import { inject, Injectable } from '@angular/core';
import { CapacitorHttp } from '@capacitor/core';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import cloneDeep from 'lodash-es/cloneDeep';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { ShoppingCartSubmitService } from '../../../common/resources/src/services/shopping-cart-submit.service';
import { isPharmacyChatUser } from '../../../essentials/types/src/chatUser';
import { RemoveCommonChatDuettFields } from '../../../essentials/types/src/common-chat-duett-fields';
import { SendShoppingCartToWawi } from '../../../essentials/types/src/duett-schema/de.meineapotheke.chat/send_shopping_cart_to_wawi/jsonschema/1-0-0';
import { CONFIG } from '../../../essentials/types/src/mea-config';
import { ShopCoopServiceInterface } from '../../../essentials/types/src/service-interfaces/shop-coop.service.interface';
import { SubmittedShoppingCart } from '../../../essentials/types/src/shoppingCart';
import { ShopBillingAddress } from '../../../essentials/types/src/shopSale';
import { Logger } from '../../../essentials/util/src/logger';
import { ShopCoopUtil } from '../../../essentials/util/src/shop-coop.util';
import { ShoppingCartUtil } from '../../../essentials/util/src/shopping-cart.util';
import {
  selectActiveConversation,
  selectActiveConversationId,
} from '../../../store/src/common-store/chat-store/selectors/chat.selectors';
import { CommonState } from '../../../store/src/common-store/common.state';
import { analyticsDuettEvent } from '../../../store/src/common-store/other/actions/common-analytics.actions';
import { selectUser } from '../../../store/src/common-store/user-store/selectors/user.selectors';
import { ChatPartnerWithMetadata } from '../types/chatPartner';
import { AppsyncShopCoopService } from './appsync-shop-coop.service';
import { ChatConversationsService } from './chat-conversations.service';

const logger = new Logger('ShopCoopService');

@Injectable({ providedIn: 'root' })
export class ShopCoopService implements ShopCoopServiceInterface {
  private appsyncShopCoopService = inject(AppsyncShopCoopService);
  private shoppingCartSubmitService = inject(ShoppingCartSubmitService);
  private store: Store<CommonState> = inject(Store);
  private config = inject(CONFIG);
  private chatConversationsService = inject(ChatConversationsService);

  private isProd = this.config.name === 'prod';

  sanacorpCustomerId$ = this.store
    .select(selectUser)
    .pipe(map((user) => (user && isPharmacyChatUser(user) ? user.cognitoUsername : undefined)));

  async sendShoppingCartToShop(shoppingCart: SubmittedShoppingCart): Promise<boolean> {
    try {
      const sanacorpCustomerId = await firstValueFrom(this.sanacorpCustomerId$);
      if (!sanacorpCustomerId) {
        return false;
      }
      const conversationId = (await firstValueFrom(this.store.select(selectActiveConversationId))) ?? undefined;
      const activeEnduserChatPartner = await firstValueFrom(this.chatConversationsService.activeEnduserChatPartner$);
      const sanacorpCustomerIdForBody = this.isProd ? sanacorpCustomerId : '999999';
      const billingAddress = this.getBillingAddress(activeEnduserChatPartner);
      if (!billingAddress) {
        return false;
      }
      const shopSale = ShopCoopUtil.mapShoppingCartToShopSale({
        shoppingCart,
        sanacorpCustomerId: sanacorpCustomerIdForBody,
        conversationId,
        billingAddress,
      });
      const shopSecrets = await this.appsyncShopCoopService.getShopCoopSecrets();
      if (!shopSecrets) {
        return false;
      }
      let url = 'https://coop-api-approval.shop.production.curacado.cloud/shops/999999/sales';
      if (this.isProd) {
        url = `https://coop-api.shop.production.curacado.cloud/shops/${sanacorpCustomerId}/sales`;
      }
      const result = await CapacitorHttp.post({
        url,
        data: shopSale,
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': shopSecrets.apiKey,
          'User-Agent': (await UserAgent.get()).userAgent,
        },
      });
      return result.status === 200;
    } catch (e) {
      logger.error('Error sending shopping cart to shop', e);
      return false;
    }
  }

  async updateSubmittedShoppingCart(shoppingCart: SubmittedShoppingCart): Promise<SubmittedShoppingCart | undefined> {
    const conversation = await firstValueFrom(this.store.select(selectActiveConversation));
    if (!conversation) {
      return undefined;
    }
    const updatedShoppingCart = cloneDeep(shoppingCart);
    updatedShoppingCart.sentToShopTimestamp = dayjs().toISOString();
    const updateShoppingCartSuccess = await this.shoppingCartSubmitService.updateShoppingCartInConversation(
      conversation,
      updatedShoppingCart
    );
    return updateShoppingCartSuccess ? updatedShoppingCart : undefined;
  }

  async trackSendShoppingCartToWawi(shoppingCart: SubmittedShoppingCart) {
    const conversation = await firstValueFrom(this.store.select(selectActiveConversation));
    if (!conversation) {
      return;
    }
    const sanacorpCustomerId = await firstValueFrom(this.sanacorpCustomerId$);
    if (!sanacorpCustomerId) {
      return;
    }
    const { eHealthPrescriptions, eRezepts, prescriptionImages } =
      ShoppingCartUtil.getNumberOfPrescriptionItems(shoppingCart);
    const event: RemoveCommonChatDuettFields<SendShoppingCartToWawi> = {
      event_type: 'send_shopping_cart_to_wawi',
      sanacorp_customer_number: sanacorpCustomerId,
      frontend_conversation_id: conversation.id,
      number_unique_products: shoppingCart.products.length,
      number_products_total: ShoppingCartUtil.getTotalNumberOfProductItems(shoppingCart.products),
      number_prescriptions_total: eRezepts + prescriptionImages + eHealthPrescriptions,
      number_e_health_prescriptions: eHealthPrescriptions,
      number_prescription_erezepts: eRezepts,
      number_prescription_images: prescriptionImages,
      total_price: ShoppingCartUtil.getTotalPriceCategory(shoppingCart.products),
      floored_total_price: ShoppingCartUtil.getFlooredTotalPrice(shoppingCart.products),
    };
    this.store.dispatch(analyticsDuettEvent({ event }));
  }

  private getBillingAddress(activeEnduserChatPartner?: ChatPartnerWithMetadata): ShopBillingAddress | undefined {
    const dummyUserEmail = this.getDummyUserEmail(activeEnduserChatPartner?.chatPartnerId);
    const firstName = activeEnduserChatPartner?.chatPartnerFirstName;
    const lastName = activeEnduserChatPartner?.chatPartnerLastName;
    if (!dummyUserEmail || !firstName || !lastName) {
      return undefined;
    }
    return { firstName, lastName, email: dummyUserEmail };
  }

  private getDummyUserEmail(cognitoId?: string) {
    const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;
    const cognitoUuid = cognitoId?.match(uuidRegex)?.[0];
    return cognitoUuid ? `AppUser-${cognitoUuid}@curaca.do` : undefined;
  }
}
