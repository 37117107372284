import gql from 'graphql-tag';
import { messageAttributes, pharmacyAttributes, pharmacyPharmacyChatUserAttributes } from '../attributes';

export const backendUserConversationQueryAttributes = `
        id
        conversationId
        ownerId
        participantsIds
        encryptedConversationPassword
        participants {
          __typename
          cognitoId
          chatname
          isGuest
          isAppUser
          userType
          userStatus
          publicKey
          pharmacy {
            ${pharmacyAttributes}
            pharmacyChatUser {
              ${pharmacyPharmacyChatUserAttributes}
            }
          }
        }
        conversation {
          id
          firstSegmentId
          createdAt
          intent
          appContext
          archivedByEnduser
          earliestExpirationTimestamp
          deletionRecord {
            cognitoId
            deletedAt
          }
          ticketHistory {
            timestamp
            updatedStatus
            updatedBy
            accepted
          }
          encryptedShoppingCart
          encryptedAppointment
          hasPrescriptions
          appointmentId
          lastMessage {
            ${messageAttributes}
          }
        }
        conversationLink
        encryptedChatPartnerNickname
        reminderNotification
        showReminder`;

export default gql`
  query getUserConversations($nextToken: String) {
    getUserConversations(nextToken: $nextToken) {
      __typename
      nextToken
      userConversations {
        ${backendUserConversationQueryAttributes}
      }
    }
  }
`;
